
.checkout{
    &__title {
        margin-top: 144px;
        margin-bottom:80px;
        @media(max-width: 992px) {
            margin-top: 0;
            margin-bottom: 40px;
        }

        &.mb-139{
            margin-bottom: 139px;
            @media(max-width: 992px) {
                margin-bottom: 40px;
            }
        }

        &.examRegistration{
            margin-bottom: 28px;
            @media(max-width: 992px) {
                margin-bottom: 40px;
            }
        }
        &.examRegistrationRequest{
            margin-bottom: 60px;
            @media(max-width: 992px) {
                margin-bottom: 40px;
            }
        }
        &.application{
            margin-bottom: 120px;
            @media(max-width: 992px) {
                margin-top: 20px;
                margin-bottom: 40px;
            }
        }

        &.application-confirmation{
            margin-bottom: 60px;
            @media(max-width: 992px) {
                margin-top: 20px;
                margin-bottom: 40px;
            }
        }

        h1, h2, h3, h4 {
            color: #506279;
            font-size: 65px;
            line-height: 75px;
            @include font('Open Sans', sans-serif, 400, normal);
            word-break: break-word;
            @media(max-width: 992px) {
                font-size: 36px;
                line-height: 41px;
                margin-bottom: 41px;
                text-align: center;
            }
        }
        .back-link {
            margin-bottom: 34px;
          a{
            text-decoration: none;
            color: rgba(0, 0, 0, 0.3);
            text-transform: uppercase;
            font-size: 13px;
            line-height: 38px;
          }
          @media(max-width: 992px) {
              display: none;
          }
        }
        &__logo{
            width: 100%;
            max-width: 65%;
            float: right;
            @media(max-width: 992px) {
                max-width: 60%;
            }
            @media(max-width: 520px) {
                max-width: 65%;
            }
            img{
                width: 100%;
                padding-left: 20px;
                @media(max-width: 460px) {
                    padding-left: 5px;
                }
            }
        }
        &__intro {
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 21px;
            line-height: 36px;
            color: #121212;
            hyphens: auto;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            @media(max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
            }
            p{
                margin-bottom: 20px;
                @media(max-width: 992px) {
                    margin-bottom: 15px;
                }
            }
        }
    }
    &__edit-info {
        font-size: 21px;
        line-height: 36px;
        a {
            @include font('Open Sans', sans-serif, 300, normal);
            padding-bottom: 8px;
            border-bottom: 2px solid rgba($color: #F68D6F, $alpha: .6);
            @media(max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
                display: inline-block;
                padding-bottom: 0;
            }
        }
        .text-right{
            @media(max-width: 992px) {
                text-align: left!important;
            }
        }
        .editBtn{
            @media(max-width: 992px) {
                margin-top: 15px;
            }
        }
        @media(max-width: 992px) {
            font-size: 17px;
            line-height: 28px;
        }
        &__type{
            color: #B4B4B4;
            @include font('Open Sans', sans-serif, 300, normal);
            position: relative;
        }
        .form-group {
            margin-bottom: 0;
        }

        &__spacer{
            @media(max-width: 992px) {
                margin-top: 20px;
                width: 100%;
            }

        }

        &__select{

            .item.select{
                @media(max-width: 575px) {
                    width: initial;
                }
                .item-selected{
                    padding-right: 25px;
                }
                a{
                    @include font('Open Sans', sans-serif, 400, normal);
                    font-size: 21px;
                    line-height: 36px;
                    color: #121212;
                    border-bottom: none;
                    text-decoration: none;
                    @media(max-width: 992px) {
                        font-size: 17px;
                        line-height: 28px;
                    }
                    b{
                        @include font('Open Sans', sans-serif, 400, normal);
                        font-weight: 400;
                        position: relative;
                        span{
                            position: relative;
                            &:after {
                                background-image: url(../images/./icons/arrow-down.svg);
                                background-repeat: no-repeat;
                                background-position: center center;
                                width: 10px;
                                height: 13px;
                                content: "";
                                display: inline-block;
                                background-size: cover;
                                position: absolute;
                                right: -19px;
                                top: 8px;
                                @media(max-width: 992px) {
                                    right: -15px;
                                    top: 4px;
                                }
                            }
                        }
                    }
                }

                .options{
                    display: none;
                    position: relative;
                    span{
                        display: block;
                        a{
                            border-bottom: 2px solid rgba(246,141,111,0);
                            padding-bottom: 0;
                            font-size: 17px;
                            line-height: 28px;
                            display: inline;
                            word-break: break-word;
                            @media(max-width: 992px) {
                                font-size: 17px;
                                line-height: 20px;
                            }
                            &.selected{
                                border-bottom: 2px solid rgba(246,141,111,1);
                            }
                        }

                    }
                }

                &.active{
                    z-index: 5;
                    position: relative;
                    padding: 0;
                    width: 100%;
                    max-width: 350px;
                    @media (max-width: 992px) {
                        padding: 0;
                    }
                    @media (max-width: 575px) {
                        max-width: 100%;
                    }

                    &:before{
                        content: "";
                        position: absolute;
                        width: calc(100% + 80px);
                        height: calc(100% + 80px);
                        background: #ffffff;
                        top: -40px;
                        left: -40px;
                        // z-index: -1;
                    }

                    .overlaySelect{
                        position: fixed;
                        width: 100vw;
                        height: 100vh;
                        background: rgba(18, 18, 18, 0.5);
                        top: 0;
                        left: 0;
                        z-index: -2;
                    }

                    .item-selected{
                        display: none;
                    }

                    *{
                        font-size: 21px;
                        line-height: 36px;
                        @media (max-width: 992px) {
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }
                    .options{
                        display: block;
                    }
                }










            }
        }





    }
    &__section{
        color: #000000;
        padding: 53px 0 62px 0;
        border-top: 1px solid #C4C4C4;
        @media(max-width: 992px) {
            padding: 23px 0 24px 0;
        }
        &.student{
            padding: 60px 0 20px 0;
            @media(max-width: 992px) {
                padding: 23px 0 24px 0;
            }
            &.mb-128{
                margin-bottom: 128px;
                @media(max-width: 992px) {
                    margin-bottom: 0;
                }
            }
        }
        &.request{
            padding: 60px 0 40px 0;
            @media(max-width: 992px) {
                padding: 23px 0 24px 0;
            }
        }
        &.section-readonly{
            padding: 44px 0 40px 0;
            @media(max-width: 992px) {
                padding: 23px 0 12px 0;
            }
            .form-group{
                margin-bottom: 50px;
                @media(max-width: 992px) {
                    margin-bottom: 25px;
                }
            }
        }
        &.formGroup{
            padding: 55px 0 55px 0;
            @media(max-width: 992px) {
                padding: 23px 0 12px 0;
            }
            &.btnFile{
                padding: 55px 0 15px 0;
                @media(max-width: 992px) {
                    padding: 23px 0 10px 0;
                }
            }
            .styled-radio{
                label[for="terms"]{
                    @media(max-width: 992px) {
                        margin-top: 30px;
                        font-size: 16px;
                    }
                }
            }
        }
        &.formGroup-confirmation{
            padding: 60px 0 50px 0;
            @media(max-width: 992px) {
                padding: 20px 0 0 0;
            }
        }

        .radioItem{
            @media(max-width: 359px) {
                max-width: 250px;
            }
            .item{
                label{
                    font-size: 19px;
                    line-height: 22px;
                    font-weight: 300;
                    width: auto;
                    @media(max-width: 992px) {
                        font-size: 16px;
                        line-height: 18px;
                        display: inline;
                        letter-spacing: -.3px;
                    }
                    @media(max-width: 405px) {
                        font-size: 14px;
                        line-height: 16px;
                    }
                    @media(max-width: 359px) {
                        font-size: 16px;
                        line-height: 18px;
                    }
                    &:after{
                        // top: 50%;
                        // transform: translateY(-50%);
                    }
                }
                .btn-help{
                    @media(max-width: 405px) {
                        height: 20px;
                        width: 20px;
                        font-size: 14px;
                        line-height: 20px;
                    }

                    @media(max-width: 359px) {
                        height: 27px;
                        width: 27px;
                        font-size: 19px;
                        line-height: 27px;
                    }
                }
            }
            .btn.btn-help.popUp-regulation-toggle{
                display: none;
                margin-left: 0;
                @media(max-width: 992px) {
                    display: inline-block;
                }
            }
            a{
                font-weight: 400;
                &.link-orange.popUp-regulation-toggle{
                   @media(max-width: 992px) {
                        border-bottom: none;
                        font-weight: 300;
                        color: #000000;
                    }
                }
            }
        }

        > h5{
            font-size: 36px;
            line-height: 38px;
            color: #506279;
            margin-bottom: 23px;
            font-weight: 400;
           @media(max-width: 992px) {
                font-size: 24px;
                line-height: 38px;
                margin-bottom: 17px;
                text-align: center;
            }
        }
        p {
            font-size: 1.25rem;
            @include font('Open Sans', sans-serif, 300, normal);
            @media(max-width: 992px) {
                font-size: 1rem;
            }
        }
        .box-orange{
            margin-top: 8px;
            padding: 48px 59px;
            margin-bottom: 32px;
            @media(max-width: 992px) {
                padding: 24px;
            }
            p{
                @include font('Open Sans', sans-serif, 300, normal);
                font-size: 21px;
                line-height: 36px;
                color: #121212;
                @media(max-width: 992px) {
                    font-size: 17px;
                    line-height: 28px;
                }
            }
        }
        .box-gray{
            margin-top: 5px;
            font-size: 24px;
            line-height: 38px;
            padding: 38px 96px 38px 74px;
            min-height: 186px;
            max-width: 862px;
            @media(max-width: 992px) {
                padding: 24px;
                min-height: auto;
            }
            p{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 21px;
                line-height: 36px;
                color: #121212;
                @media(max-width: 992px) {
                    font-size: 17px;
                    line-height: 28px;
                }
            }
            &.mbCheckout{
                margin-bottom: 460px;
                @media(max-width: 992px) {
                    margin-bottom: 80px;
                }
            }
        }
        .radio-card p {
            font-size: 1.125rem;

            @media(max-width: 992px) {
                font-size: 1rem;
            }
        }
        .checkout__close__basket{
            .items{
                padding: 0 0 0 20px;
                padding-bottom: 12px;
                li {
                    @include font('Open Sans', sans-serif, 300, normal);
                    font-size: 21px;
                    line-height: 36px;
                    color: #000000;
                    padding-left: 8px;
                    margin-bottom: 8px;
                    position: relative;
                    padding-right: 20%;
                    @media(max-width: 992px) {
                        font-size: 17px;
                        line-height: 28px;
                        padding-right: 100px;
                    }
                    div {
                        word-break: break-word;
                    }
                    .value{
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: #483A54;
                        @include font('Open Sans', sans-serif, 400, normal);
                    }
                }
            }
            &__total{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 21px;
                line-height: 36px;
                color: #483A54;
                position: relative;
                padding-right: 20%;
                margin-bottom: 88px;
                padding-top: 20px;
                border-top: 1px solid rgba($color: #483A54, $alpha: .3);
                @media(max-width: 992px) {
                    font-size: 17px;
                    line-height: 28px;
                    padding-right: 120px;
                    margin-bottom: 20px;
                }
                div {
                    word-break: break-word;
                }
                .value{
                    position: absolute;
                    top: 20px;
                    right: 0;
                }

            }
        }
        .form-payment-radio{
            margin-bottom: 60px;
            @media(max-width: 992px) {
                margin-bottom: 30px;
            }
            .styled-radio{
                label{
                    @media(max-width: 992px) {
                        font-size: 16px;
                    }
                }
            }
        }
        .styled-radio input[type=checkbox]+label:after, .styled-radio input[type=radio]+label:after {
            top: 2px;
        }

        .file-size-error {
            font-size: 1rem;
            color: $brandRed;

            @media(max-width: 992px) {
                font-size: 0.875rem;
            }
        }
    }
    &__bottom {
        background-color: #DDEBEE;
        padding-top: 60px;
        padding-bottom: 150px;
        @media(max-width: 992px) {
            padding: 32px 0 63px 0px;
        }
        &.confirmation{
            padding-bottom: 120px;
            @media(max-width: 992px) {
                padding-bottom: 100px;
            }
        }
        &.total{
            padding-bottom: 120px;
            @media(max-width: 992px) {
                padding: 32px 0 62px 0px;
            }
        }
        .link-b-orange{
            @include font('Open Sans', sans-serif, 400, normal);
            border-bottom: 2px solid #F6CDD6;
        }
        h5 {
            color: #506279;
            font-size: 36px;
            line-height: 38px;
            @include font('Open Sans', sans-serif, 400, normal);
            margin-bottom: 58px;
            @media(max-width: 992px) {
                font-size: 36px;
                line-height: 38px;
                text-align: center;
                margin-bottom: 34px;
            }
        }
        h6{
            font-size: 25px;
            line-height: 36px;
            color: #483A54;
            margin-bottom: 20px;
            @media(max-width: 992px) {
                margin-bottom: 12px;
                font-size: 20px;
                line-height: 28px;
            }
        }
        &--htmlArea{
            margin-bottom: 52px;
            font-size: 21px;
            line-height: 36px;
            color: #000000;
            @include font('Open Sans', sans-serif, 300, normal);
            @media(max-width: 992px) {
                font-size: 17px;
                line-height: 28px;
            }
            p{
                margin-bottom: 15px;
            }
            ul,ol {
                padding-left: 20px;
                margin-bottom: 15px;
                li {
                    padding: 0 0 0 13px;
                    font-size: 21px;
                    line-height: 36px;
                    color: #000000;
                    @include font('Open Sans', sans-serif, 300, normal);
                    margin-bottom: 8px;
                    @media(max-width: 992px) {
                        padding: 0 0 0 3px;
                        font-size: 17px;
                        line-height: 28px;
                        margin-bottom: 4px;
                    }
                }
            }
        }
        .btn-primary{
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 26px;
            line-height: 26px;
            display: block;
            padding: 23px 46px;
            @media(max-width: 992px) {
                padding: 20px 30px;
                font-size: 20px;
                line-height: 23px;
                border-radius: 40px;
                width: 100%;
                max-width: 344px;
                margin: 0 auto;
            }
        }
        &__content {
            margin-bottom: 68px;
            @media(max-width: 992px) {
                margin-bottom: 34px;
            }
        }
        input[type=checkbox]+label {
            @include font('Open Sans', sans-serif, 300, normal);
            font-size: 24px;
            line-height: 30px;
            color: #000000;
           @media(max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
            }
            &:after,
            &:before{
                width: 32px;
                height: 32px;
            }
        }
        .invalid {
            label {
                color: red !important;
            }
        }

    }



    &__text-info {
        @include font('Open Sans', sans-serif, 300, normal);
        font-size: 21px;
        line-height: 36px;
        color: #121212;
        @media(max-width: 992px) {
            font-size: 15px;
            line-height: 24px;
        }
        .box-orange {
            &:not(:last-child){
                margin-bottom: 60px;
            }
        }
        .link-b-orange{
            @include font('Open Sans', sans-serif, 400, normal);
        }
        p{
            margin-bottom: 15px;
            + textarea {
                margin-top: 20px;
            }
            @media(max-width: 992px) {
                margin-bottom: 5px;
            }
        }
        h5 {
            color: $brandPurple;
            @include font('Open Sans', sans-serif, 400, normal);
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 44px;
            color: #483A54;
            @media(max-width: 992px) {
                font-size: 20px;
                line-height: 32px;
            }
        }
        h6 {
            @include font('Open Sans', sans-serif, 400, normal);
            margin-bottom: 15px;
        }
        ul {
            padding-left: 20px;
            padding-bottom: 30px;
            @media(max-width: 992px) {
                padding-bottom: 15px;
            }
            li{
                padding: 0  0 15px 4px;
                @media(max-width: 992px) {
                    padding: 0  0 5px 4px;
                }

            }
        }


        &__basket {
            padding-bottom: 60px;
            &__total {
                font-size: $p-m;
                color: $brandPurple;
                @include font('Open Sans', sans-serif, 400, normal);
                padding-top: 15px;
                border-top: 1px solid $grey;
               @media(max-width: 992px) {
                    font-size: 0.9375rem; //15
                }
                div{
                    float: right;
                }
            }
            ul {
                padding-left: 20px;
                li{
                    font-size: $p-m;
                    @media(max-width: 992px) {
                        font-size: 0.9375rem; //15
                    }
                    div {
                        color: $brandPurple;
                        float: right;
                    }
                    padding: 0  0 15px 4px;

                }
            }
        }
        &.withTitle{
            margin-top: 60px;
            font-size: 24px;
            line-height: 38px;
            font-weight: 400;
            margin-bottom: 50px;
            @media(max-width: 992px) {
                margin-top: 10px;
                font-size: 17px;
                line-height: 24px;
            }
            ul{
                padding-bottom: 32px;
                @media(max-width: 992px) {
                    padding-bottom: 20px;
                }
                li{
                    color: #000000;
                    padding: 0 0 8px 10px;
                }
            }
            h6{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 24px;
                line-height: 38px;
                color: #121212;
                margin-bottom: 0;
                @media(max-width: 992px) {
                    font-size: 17px;
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }
            p{
                margin-bottom: 4px;
            }
        }
    }

    &__article{
        .divider{
            border-top: 1px solid #C4C4C4;
            margin-bottom: 76px;
            @media (max-width: 992px) {
                margin-bottom: 60px;
            }
        }

        .article{
            margin-bottom: 70px;
            @media (max-width: 992px) {
                margin-bottom: 32px;
            }
        }

        .article__html-area{
            h1, h2, h3, h4, h5, h6{
            line-height: 38px;
            color: #506279;
            }

            p, ul, ol{
                font-weight: 300;
                font-size: 22px;
                line-height: 30px;
                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }

    }

}

.basket{
    &__item{
        @include font('Open Sans', sans-serif, 300, normal);
        font-size: $p-m; //21px
        position: relative;
        &:last-child{
            margin-bottom: 285px;
            @media(max-width: 992px) {
                margin-bottom: 11px;
            }
        }
       @media(max-width: 992px) {
            font-size: 0.9375rem; //15
        }
        &__title{
            @include font('Open Sans', sans-serif, 300, normal);
            color: #000000!important;
            font-size: 28px;
            line-height: 32px;
            padding-right: 50px;
            margin-bottom: 8px;
            @media(max-width: 992px) {
                margin-bottom: 10px;
                font-size: 18px;
                line-height: 26px;
                max-width: 260px;
                @include font('Open Sans', sans-serif, 400, normal);
            }
            a{
                text-decoration: none;
                color: #000000!important;
                @media(max-width: 992px) {
                    color: #506279!important;
                }
            }
        }
        &__category{
            font-size: 20px;
            line-height: 23px;
            color: #8D8E9B;
            @include font('Open Sans', sans-serif, 300, normal);
            @media(max-width: 992px) {
                font-size: 16px;
                line-height: 26px;
            }
        }
        &__price {
            font-size: 21px;
            line-height: 36px;
            color: #121212;
            @include font('Open Sans', sans-serif, 300, normal);
            margin-top: 24px;
            display: block;
            position: relative;
            padding-right: 20%;
            word-break: break-word;
            @media(max-width: 992px) {
                font-size: 15px;
                line-height: 24px;
                padding-right: 100px;
            }
            &__value {
                color: $brandPurple;
                @include font('Open Sans', sans-serif, 400, normal);
                text-align: right;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        &--add{
            text-align: left;
            padding: 71px 71px 71px 71px;
            border-radius: 0;
            margin-bottom: 460px;
            background-color: #E1DFD8;
            width: 100%;
            display: block;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 24px;
            line-height: 38px;
            color: #121212;
            &:focus{
                box-shadow: none;
            }
            @media(max-width: 992px) {
                padding: 25px;
                font-size: 15px;
                line-height: 28px;
                margin-top: 20px;
                margin-bottom: 80px;
            }
            &:before {
                content: '';
                margin-right: 20px;
                vertical-align: middle;
                width: 20px;
                height: 20px;
                display: inline-block;
                background-image: url(../images/icons/plus.svg);
                background-repeat: no-repeat;
                @media(max-width: 992px) {
                    width: 15px;
                    height: 15px;
                    background-size: 15px;
                }
            }
            &:hover, &:focus-within {
                text-decoration: underline;
            }
        }
        &__box{
            padding: 44px;
            border: 1px solid rgba(72,58,84,0.2);
            margin-top: 24px;

            width: calc(71.43% - 10px);
            border-radius: 12px;
            @media(max-width: 992px) {
                padding: 15px;
                width: 100%;
            }
            h4, h5, h6 {
                // font-family: "SuisseIntl-Regular", sans-serif;
                margin-bottom: 24px;
            }
            .styled-radio {
                input[type= checkbox],
                input[type= radio] {
                    + label {
                        font-size: 1.125rem;
                        p {
                            font-size: 1.125rem;
                            @media(max-width: 992px) {
                                font-size: $p;
                            }
                        }
                        h5 {
                            font-family: "SuisseIntl-Book", sans-serif;
                            font-size: $p-m;
                            color: $brandPurple;
                            margin-bottom: 15px;
                            @media(max-width: 992px) {
                                font-size: $p;
                            }
                        }
                        &:after {
                            top: 0;

                        }
                    }
                }
                + .styled-radio {
                    margin-top: 21px;
                }
            }
        }
        .btn-remove{
            position: absolute;
            right: 0;
            background-size: 40px;
            @media(max-width: 992px) {
                background-size: 20px;
            }
        }

    }
    &__total {
        &__items {
            color: $textDark2;
            padding: 0 0 0 20px !important;
            margin-bottom: 48px!important;
            @media(max-width: 992px) {
                margin-bottom: 25px!important;
            }
            li {
                font-size: 24px;
                line-height: 28px;
                @include font('Open Sans', sans-serif, 300, normal);
                padding: 0 0 20px 20px;
                position: relative;
                @media(max-width: 992px) {
                    Font-size: 20px;
                    line-height: 23px;
                    padding: 0 0 20px 8px;
                }
                // padding-right: 20%!important;
                &::marker {
                    color: #506279;
                }
                div {
                    word-break: break-word;
                    // display: flex;
                    // justify-content: space-between;

                    @media(max-width: 992px){
                        font-size: 20px;
                        line-height: 23px;
                    }

                    &:first-child{
                        @media(min-width: 993px){
                            max-width: 80%;
                        }
                    }
                }
                .value{
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #483A54;
                    @include font('Open Sans', sans-serif, 400, normal);
                    @media(max-width: 992px) {
                        position: relative;
                        top: initial;
                        right: initial;
                    }
                }
            }
        }
        &__value {
            color: $brandPurple;
            @include font('Open Sans', sans-serif, 400, normal);
            font-size: 29px;
            line-height: 44px;
            color: #483A54;
            padding-top: 40px;
            border-top: 1px solid $grey;
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
           @media(max-width: 992px) {
                font-size: $p-m;
            }

        }
        .btn-primary{
            display: inline-block;
            @media(max-width: 992px) {
                display: block;
            }
        }
    }
}

.mb-155{
    @media(max-width: 992px) {
        margin-bottom: 70px;
    }
}

.step2, .step3, .step4, .stepOptionalDocumentation{
    display: none;
}


// Checkout error page
#checkoutError{
    .fail__section{
        margin-bottom: 418px;

        @media(max-width: 768px) {
            margin-bottom: 80px;
        }

        .button__wrapper{
            margin-top: 80px;

            @media(max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 30px;
            }

            button{
                margin-right: 30px;
                padding: 23px 52px;
                border-radius: 62px;
                font-size: 26px;
                line-height: 26px;

                &:last-child{
                    margin-right: 0;
                }

                @media(max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 30px;
                    padding: 14px 44px;
                    font-size: 17px;
                    line-height: 28px;
                    border-radius: 38px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }

        .box-gray{
            margin-bottom: 75px;
            @media(max-width: 992px) {
                margin-bottom: 50px;
            }
        }

    }

    .contact__section{
        display: none;

        .user-info{
            margin-top: 20px;

            @media(max-width: 992px) {
                margin-top: 0;
            }
        }
    }
}

.popUp-regulation-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(132, 132, 132, .6);
    z-index: 99999;
    padding: 50px 110px;
    display: none;
    @media(max-width: 992px) {
        padding: 15px;
    }
    &__content{
        width: 100%;
        max-width: 1051px;
        background: #ffffff;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        &--header{
            background: #DDEBEE;
            padding: 38px 200px 13px 127px;
            position: relative;
            @media(max-width: 992px) {
                padding: 58px 50px 30px 18px;
            }
            .close-popUp{
                position: absolute;
                right: 48px;
                top: 50%;
                transform: translateY(-50%);
                background: url("../images/./icons/medlearn/close-popUp.svg") no-repeat center center;
                background-size: 100%;
                width: 25px;
                height: 23px;
                border: none;
                @media(max-width: 992px) {
                    right: 18px;
                    top: 24px;
                }
            }
            h2{
                @include font('Open Sans', sans-serif, 400, normal);
                font-size: 25px;
                line-height: 38px;
                color: #506279;
                text-transform: uppercase;
                @media(max-width: 992px) {
                    font-size: 16px;
                    line-height: 26px;
                }
                @media(max-width: 768px) {
                    max-width: 250px;
                }
            }
        }
        &--text{
            margin: 42px 96px 55px 130px;
            padding-right: 20px;
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: thin;
            color: #000000;

            @media(max-width: 992px) {
                margin: 21px 18px 21px 18px;
                padding-right: 0;
            }

            strong{
                font-size: 18px;
                line-height: 26px;
            }

            p{
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 30px;

                @media(max-width: 992px) {
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 15px;
                }

            }

            a{
                text-decoration: underlina;
                color: #506279;
            }

            .btn{
                padding: 24px 46px;
                min-width: auto;
                font-size: 26px;
                line-height: 26px;
                margin: 80px auto 75px auto;
                display: block;
                @media(max-width: 992px) {
                    font-size: 18px;
                    line-height: 18px;
                    margin: 40px auto 20px auto;
                }
            }

            @media(min-width: 992px) {
                &::-webkit-scrollbar-track {
                    background-color: rgba(196, 196, 196, 0.5);
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background: rgba(196, 196, 196, 0.5);
                }
                &::-webkit-scrollbar-thumb {
                    background: #878787;
                }
            }

        }
    }
}

.styled-radio{
    label[for="consent"]{
        line-height: 28px;

        @media(max-width: 992px) {
            padding-left: 36px !important;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
